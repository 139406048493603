import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import product_pc01 from '../../assets/img/product_pc01.png';
import product_pc02 from '../../assets/img/product_pc02.png';
import product_pc05 from '../../assets/img/nftbrowsing-left.png';
import product_pc06 from '../../assets/img/nftbrowsing-right.png';
import product_pc07 from '../../assets/img/product_pc07.png';
import product_pc08 from '../../assets/img/Built-in multi-chain Web3 browser0.png';
import product_pc09 from '../../assets/img/product_pc09.png';
import product_pc10 from '../../assets/img/product_pc10.png';
import product_pc11 from '../../assets/img/product_pc11.png';
import product_pc12 from '../../assets/img/product_pc12.png';
import product_pc13 from '../../assets/img/product_pc13.png';
import product_pc14 from '../../assets/img/product_pc14.png';
import product_pc15 from '../../assets/img/product_pc15.png';
import product_pc16 from '../../assets/img/product_pc16.png';
import product_pc17 from '../../assets/img/product_pc17.png';
import product_pc18 from '../../assets/img/product_pc18.png';
import product_pc19 from '../../assets/img/product_pc19.svg';
import product_pc20 from '../../assets/img/product_pc20.svg';
import product_pc21 from '../../assets/img/product_pc21.png';
import product_pc22 from '../../assets/img/product_pc22.png';
import product_pc23 from '../../assets/img/product_pc23.png';
import product_mb01 from '../../assets/img/product_mb01.png';
import product_mb09 from '../../assets/img/product_mb09.png';
import product_mb03 from '../../assets/img/product_mb03.png';
import product_mb04 from '../../assets/img/product_mb04.png';
import product_mb05 from '../../assets/img/product_mb05.png';
import product_mb06 from '../../assets/img/product_mb06.png';
import product_mb07 from '../../assets/img/product_mb07.png';
import product_mb08 from '../../assets/img/product_mb08.png';
import product_pc24 from '../../assets/img/multichain-integration.png';
import product_pc25 from '../../assets/img/rocket_DeFi.png';
import product_pc26 from '../../assets/img/multi_chain_nft.svg';
import product_pc27 from '../../assets/img/safe1.png';
import product_pc28 from '../../assets/img/safe2.png';
import product_pc29 from '../../assets/img/marketplace01.png';
import product_pc30 from '../../assets/img/marketplace02.png';
import product_mb29 from '../../assets/img/marketplace1.svg';
import product_mb30 from '../../assets/img/marketplace2.svg';
import product_pc31 from '../../assets/img/launchpad.png';

function Product(): ReactElement {
  const { t } = useTranslation('common');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="product-page">
      <div className="wrapper">
        {/* NFT Wallet section 01 */}
        <div className="section-nft-wallet-01" id="nft-wallet-des">
          <div className="wrapper">
            <div className="content hidden-mobile">
              <div className="text-header" dangerouslySetInnerHTML={{ __html: t('product.section1_text1') }}></div>
              <div className="text-middle">{t('product.section1_text2_pc')}</div>
            </div>
            <div className="img-product-pc02">
              <img src={product_pc15} alt="image NFT Art" />
            </div>
            <div className="content hidden-pc">
              <div className="text-header" dangerouslySetInnerHTML={{ __html: t('product.section1_text1') }}></div>
              <div className="text-middle">{t('product.section1_text2_mb')}</div>
            </div>
          </div>
        </div>
        {/* NFT Wallet section 02 */}
        <div className="section-nft-wallet-02">
          <div className="wrapper">
            <div className="content-header">
              <div className="text-header">{t('product.section2_text1')}</div>
              <div className="text-bottom">{t('product.section2_text2_pc')}</div>
            </div>
            <div className="img-product-pc04">
              <div className="position-relative">
                <img className="img" src={product_pc24} alt="multi-chain-integration" />
              </div>
            </div>
          </div>
        </div>
        {/* NFT Wallet section 03 */}
        <div className="section-nft-wallet-03">
          <div className="wrapper">
            <div className="content-header">
              <div className="text-header">{t('product.section3_nft_text1')}</div>
              <div className="text-bottom hidden-mobile">{t('product.section3_nft_text2_pc')}</div>
              <div className="text-bottom hidden-pc">{t('product.section3_nft_text2_mb')}</div>
            </div>
            <div className="content-bottom">
              <img className="content-img" src={product_pc26} alt="multi-chain-nft" />
            </div>
          </div>
        </div>
        {/* NFT Wallet section 04 */}
        <div className="section-nft-wallet-04">
          <div className="wrapper">
            <div className="wrapper-header">
              <div className="content">
                <div className="text-header hidden-mobile">{t('product.section4_text1_pc')}</div>
                <div className="text-header hidden-pc">{t('product.section4_text1_mb')}</div>
                <div className="text-bottom">{t('product.section4_text2')}</div>
              </div>
              <div className="img-product-pc08">
                <img src={product_pc08} alt="" />
                {/* <img src={product_mb03} className="hidden-pc" />
                <img src={product_mb04} className="hidden-pc" /> */}
                {/* <div className="text-img hidden-pc">{t('product.section4_text3_mb')}</div> */}
              </div>
            </div>
          </div>
        </div>
        {/* NFT Wallet section 05 */}
        <div className="section-nft-wallet-05">
          <div className="wrapper">
            <div className="content-header">
              <div className="text-header">{t('product.section3_text1')}</div>
              <div className="text-bottom hidden-mobile">{t('product.section3_text2_pc')}</div>
              <div className="text-bottom hidden-pc">{t('product.section3_text2_mb')}</div>
            </div>
            <div className="content-bottom">
              <div className="content-left">
                <img src={product_pc27} className="img-product-pc05" alt="" />
              </div>
              <div className="content-right">
                <img src={product_pc28} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* NFT Wallet section 06 */}
        {/* <div className="section-nft-wallet-06">
          <div className="wrapper">
            <div className="wrapper-header">
              <div className="text-header hidden-mobile">{t('product.section5_text1_pc')}</div>
              <div className="text-header hidden-pc">{t('product.section5_text1_mb')}</div>
              <div className="text-bottom hidden-mobile">{t('product.section5_text2_pc')}</div>
              <div className="text-bottom hidden-pc">{t('product.section5_text2_mb')}</div>
            </div>
            <div className="img-product-pc10">
              <img src={product_pc10} className="hidden-mobile" />
              <img src={product_mb06} className="hidden-pc" />
            </div>
          </div>
        </div> */}
        {/* NFT section 01 */}
        <div className="section-nft-01" id="nft-des">
          <div className="wrapper">
            <div className="content hidden-mobile">
              <div className="text-header" dangerouslySetInnerHTML={{ __html: t('product.section1_nft_text1') }}></div>
              <div className="text-middle">{t('product.section1_nft_text2_pc')}</div>
              {/* <img src={product_pc01} alt="logo talken" /> */}
            </div>
            <div className="img-product-pc02">
              <img src={product_pc02} alt="image NFT Art" />
            </div>
            <div className="content hidden-pc">
              <div className="text-header" dangerouslySetInnerHTML={{ __html: t('product.section1_nft_text1') }}></div>
              <div className="text-middle">{t('product.section1_nft_text2_mb')}</div>
              {/* <img src={product_mb01} alt="logo talken" /> */}
            </div>
          </div>
        </div>
        {/* NFT section 02 */}
        <div className="section-nft-02">
          <div className="wrapper">
            <div className="content-header">
              <div className="text-header">{t('product.section4_nft_text1_pc')}</div>
              <div className="text-bottom">{t('product.section4_nft_text2_pc')}</div>
            </div>
            <div className="img-product-pc04">
              <img className="img" src={product_pc09} alt="" />
            </div>
          </div>
        </div>
        {/* NFT section 03 */}
        <div className="section-nft-03">
          <div className="wrapper">
            <div className="wrapper-header">
              <div className="text-header hidden-mobile">{t('product.section5_nft_text1_pc')}</div>
              <div className="text-header hidden-pc">{t('product.section5_nft_text1_mb')}</div>
              <div className="text-bottom hidden-mobile">{t('product.section5_nft_text2_pc')}</div>
              <div className="text-bottom hidden-pc">{t('product.section5_nft_text2_mb')}</div>
            </div>
            <div className="img-product-pc10">
              <img src={product_pc31} className="hidden-mobile" alt="" />
              <img src={product_pc31} className="hidden-pc" alt="" />
            </div>
          </div>
        </div>
        {/* NFT section 04 */}
        <div className="section-nft-04">
          <div className="wrapper">
            <div className="wrapper-header">
              <div className="text-header hidden-mobile">{t('product.section9_nft_text1_pc')}</div>
              <div className="text-header hidden-pc">{t('product.section9_nft_text1_mb')}</div>
              <div className="text-bottom hidden-mobile">{t('product.section9_nft_text2_pc')}</div>
              <div className="text-bottom hidden-pc">{t('product.section9_nft_text2_mb')}</div>
            </div>
            <div className="wrapper-bottom">
              <div className="img-product-pc19">
              <img src={product_pc29} alt="Staking" />
                {/* <img src={product_pc29} className="hidden-pc" alt="Staking" /> */}
              </div>
              <div className="img-product-pc20">
              <img src={product_pc30} alt="Yield Farming" />
                {/* <img src={product_pc30} className="hidden-pc" alt="Yield Farming" /> */}
              </div>
            </div>
          </div>
        </div>

        {/* section 06 */}
        {/* <div className="section-06">
          <div className="wrapper">
            <div
              className="wrapper-left hidden-mobile"
              dangerouslySetInnerHTML={{ __html: t('product.section6_text1_pc') }}></div>
            <div
              className="wrapper-left hidden-pc"
              dangerouslySetInnerHTML={{ __html: t('product.section6_text1_mb') }}></div>
            <div className="wrapper-right">
              <div className="img-product-pc10">
                <img src={product_pc11} alt="Logo Talken" />
              </div>
              <div className="line"></div>
              <div className="wrapper-bottom">
                <a href="https://play.google.com/store/apps/details?id=io.talken.wallet">
                  <img src={product_pc12} />
                </a>
                <a href="https://apps.apple.com/app/talken/id1459475831">
                  <img src={product_pc13} />
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* DeFi section 07 */}
        <div className="section-07" id="defi-des">
          <div className="wrapper">
            <div className="wrapper-left hidden-mobile">
              <div className="text-header" dangerouslySetInnerHTML={{ __html: t('product.section7_text1') }}></div>
              <div className="text-bottom">{t('product.section7_text2_pc')}</div>
            </div>
            <div className="wrapper-right">
              <img src={product_pc25} alt="" />
            </div>
            <div className="wrapper-left hidden-pc">
              <div className="text-header" dangerouslySetInnerHTML={{ __html: t('product.section7_text1') }}></div>
              <div className="text-bottom">{t('product.section7_text2_mb')}</div>
            </div>
          </div>
        </div>
        {/* DeFi section 08 */}
        <div className="section-08">
          <div className="wrapper">
            <div className="wrapper-header">
              <div className="text-header hidden-mobile">{t('product.section8_text1_pc')}</div>
              <div className="text-header hidden-pc">{t('product.section8_text1_mb')}</div>
              <div className="text-bottom hidden-mobile">{t('product.section8_text2_pc')}</div>
              <div className="text-bottom hidden-pc">{t('product.section8_text2_mb')}</div>
            </div>
            <div className="wrapper-bottom">
              <div className="img-product-pc16">
                <img src={product_pc16} alt="" />
                <div className="text-img">{t('product.section8_text3')}</div>
              </div>
              <div className="img-product-pc17">
                <img src={product_pc17} alt="" />
                <div className="text-img">{t('product.section8_text4')}</div>
              </div>
              <div className="img-product-pc18">
                <img src={product_pc18} alt="" />
                <div className="text-img">{t('product.section8_text5')}</div>
              </div>
            </div>
          </div>
        </div>
        {/* DeFi section 09 */}
        <div className="section-09">
          <div className="wrapper">
            <div className="wrapper-header">
              <div className="text-header hidden-mobile">{t('product.section9_text1_pc')}</div>
              <div className="text-header hidden-pc">{t('product.section9_text1_mb')}</div>
              <div className="text-bottom hidden-mobile">{t('product.section9_text2_pc')}</div>
              <div className="text-bottom hidden-pc">{t('product.section9_text2_mb')}</div>
            </div>
            <div className="wrapper-bottom">
              <div className="wrapper-left">
                <div className="staking">{t('product.section9_text3')}</div>
                <div className="img-product-pc19">
                  <img src={product_pc19} alt="Staking" className="" />
                  {/* <img src={product_mb07} alt="Staking" className="hidden-pc" /> */}
                </div>
              </div>
              <div className="wrapper-right">
                <div className="yield-farming">{t('product.section9_text4')}</div>
                <div className="img-product-pc20">
                  <img src={product_pc20} alt="Yield Farming" className="" />
                  {/* <img src={product_mb08} alt="Yield Farming" className="hidden-pc" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* DeFi section 10 */}
        <div className="section-10">
          <div className="wrapper">
            <div className="wrapper-header">
              <div
                className="text-header hidden-mobile"
                dangerouslySetInnerHTML={{ __html: t('product.section10_text1_pc') }}></div>
              <div
                className="text-header hidden-pc"
                dangerouslySetInnerHTML={{ __html: t('product.section10_text1_mb') }}></div>
              <div className="text-bottom hidden-mobile">{t('product.section10_text2_pc')}</div>
              <div className="text-bottom hidden-pc">{t('product.section10_text2_mb')}</div>
            </div>
            <div className="wrapper-middle">
              <div className="img-product-pc21">
                <img src={product_pc21} alt="" />
              </div>
              <div className="text-right hidden-mobile">{t('product.section10_text3_pc')}</div>
              <div className="text-right hidden-pc">{t('product.section10_text3_mb')}</div>
            </div>
            <div className="wrapper-bottom">
              <div className="text-left hidden-mobile">{t('product.section10_text4_pc')}</div>
              <div className="img-product-pc22">
                <img src={product_pc22} alt="" />
              </div>
              <div className="text-left hidden-pc">{t('product.section10_text4_mb')}</div>
            </div>
          </div>
        </div>
        {/* DeFi section 11 */}
        {/* <div className="section-11">
          <div className="wrapper">
            <div
              className="wrapper-left hidden-mobile"
              dangerouslySetInnerHTML={{ __html: t('product.section11_text1_pc') }}></div>
            <div
              className="wrapper-left hidden-pc"
              dangerouslySetInnerHTML={{ __html: t('product.section11_text1_mb') }}></div>
            <div className="wrapper-right">
              <div className="img-product-pc23">
                <img src={product_pc23} alt="Logo TaalSwap" />
              </div>
              <div className="line"></div>
              <div className="wrapper-bottom">
                <div className="launch-app hover">Launch App Now</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default Product;
