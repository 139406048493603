import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_ko from './modules/Translate/kr.json';
import common_en from './modules/Translate/en.json';
import { values } from 'lodash';

const DETECTION_OPTIONS = {
  order: ['localStorage'],
  caches: ['localStorage'],
};
i18n

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    detection: DETECTION_OPTIONS,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: common_en,
      },
      kr: {
        common: common_ko,
      },
    },
  });

export default i18n;
