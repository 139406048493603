import { ReactElement } from 'react';
import logo_footer from '../../assets/img/logo_footer.png';
import icon_facebook from '../../assets/img/icon_facebook.png';
import icon_instagram from '../../assets/img/icon_instagram.png';
import icon_linkedin from '../../assets/img/icon_linkedin.png';
import icon_telegram from '../../assets/img/icon_telegram.png';
import icon_twitter from '../../assets/img/icon_twitter.png';
import icon_youtube from '../../assets/img/icon_youtube.png';
import icon_medium from '../../assets/img/icon_medium.png';
import icon_contact_us from '../../assets/img/nav-contact-us.png';
// import icon_gitbook from '../../assets/img/icon_gitbook.svg';
import icon_discord from '../../assets/img/icon_discord.png';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
function Footer(): ReactElement {
  const { t, i18n } = useTranslation('common');

  return (
    <div className="footer">
      {/* form submit */}
      {/* <div className="home-register">
        <div className="wrapper">
          <div className="register-header">{t('footer.section1_text1')}</div>
          <div className="text-header hidden-mobile">{t('footer.section1_text2_pc')}</div>
          <div className="text-header hidden-pc">{t('footer.section1_text2_mb')}</div>

          <div className="submit">
            <input placeholder={t('footer.section1_text3')} className="input-submit" />
            <button className="btn-submit">{t('footer.section1_text4')}</button>
          </div>
        </div>
      </div> */}
      <div className="grid-container">
        <div className="logo-footer">
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <img src={logo_footer} alt="logo footer" />
          </Link>
          <div className="">
            <div className="text-area">
              {t('pricing.section1_text2_pc')}
              {/* <div className="language">
              <span
                className="hover"
                style={{ paddingRight: '8px', color: i18n.language !== 'kr' ? '#8B9BB8' : undefined }}
                onClick={() => i18n.changeLanguage('kr')}>
                KR
              </span>
              <span className="" style={{ color: '#8B9BB8' }}>
                |
              </span>
              <span
                className="hover"
                style={{ paddingLeft: '8px', color: i18n.language !== 'en' ? '#8B9BB8' : undefined }}
                onClick={() => i18n.changeLanguage('en')}>
                EN
              </span>
            </div> */}
            </div>
          </div>
        </div>
        <div className="navbar-footer">
          <ul className="nav">
            <li>
              <a className="nav-link">
                <div onClick={() => (window.location.href = '/company')}>{t('navbar.company')}</div>
                <div className="content-center">
                  <div onClick={() => (window.location.href = '/company#about-us-des')}>About Us</div>
                  <div onClick={() => (window.location.href = '/company#contact-us-des')}>Contact Us</div>
                </div>
              </a>
            </li>
            <li>
              <a className="nav-link">
                <div onClick={() => (window.location.href = '/product')}>{t('navbar.product')}</div>
                <div className="content-center">
                  <div onClick={() => (window.location.href = '/product#nft-wallet-des')}>
                    {t('navbar.popup_text1')}
                  </div>
                  <div onClick={() => (window.location.href = '/product#nft-des')}>{t('navbar.popup_text2')}</div>
                  <div onClick={() => (window.location.href = '/product#defi-des')}>{t('navbar.popup_text3')}</div>
                </div>
              </a>
            </li>
            <li>
              <a className="nav-link">
                <div onClick={() => (window.location.href = '/partners')}>{t('navbar.partners')}</div>
              </a>
            </li>
            <li>
              {/* <a href="#" className="nav-link">
                {t('navbar.docs')}
              </a> */}
            </li>
            <li>
              {/* <a href="#" className="nav-link">
                {t('navbar.resources')}
              </a> */}
            </li>
          </ul>
        </div>
        {/* mobile */}
        {/* <div className="navbar-footer hidden-pc">
          <div className="grid-container">
            <div className="item">
              <div>{t('navbar.company')}</div>
            </div>
            <div className="item">
              <div>{t('navbar.product')}</div>
            </div>
            <div className="item">
              <div>{t('navbar.partners')}</div>
            </div>
            <div className="item">{t('navbar.docs')}</div>
            <div className="item">{t('navbar.resources')}</div>
          </div>
        </div> */}
        <div className="copy-right">
          <div className="left-menu">Copyright © 2024 Colligence, All Rights Reserved.</div>
          <div className="right-menu">
            <a className="contact-us-item hidden-mobile" href="https://forms.gle/MDa2EJaXh3Yj9pF3A" target="_blank">
              <img src={icon_contact_us} />
            </a>
            <span className="right-menu-text hidden-mobile" onClick={() => window.open("https://forms.gle/MDa2EJaXh3Yj9pF3A")}>Contact Us</span>
          </div>
        </div>
        <div className="list-fanpage">
          {/* <a className="social-item" href="https://web.telegram.org/z/">
            <img src={icon_telegram} />
          </a>
          <a className="social-item" href="https://twitter.com">
            <img src={icon_twitter} />
          </a>
          <a className="social-item" href="https://www.facebook.com/">
            {' '}
            <img src={icon_facebook} />
          </a>
          <a className="social-item" href="https://www.youtube.com/">
            <img src={icon_youtube} />
          </a>
          <a className="social-item" href="https://www.linkedin.com/">
            <img src={icon_linkedin} />
          </a>
          <a className="social-item" href="https://www.instagram.com/">
            {' '}
            <img src={icon_instagram} />
          </a>
          <a className="social-item" href="https://medium.com/">
            <img src={icon_medium} />
          </a>
          <a className="social-item" href="https://www.gitbook.com/">
            <svg width="80%" height="80%" viewBox="-150 120 1000 250" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M411.148 6.38992L649.535 125.583C660.551 131.091 660.723 146.751 649.831 152.499L340.222 315.904C322.959 325.015 302.362 325.241 284.903 316.512L90.5121 219.316C66.8302 209.802 40.3508 227.178 40.3508 253.351C40.3508 274.394 52.2398 293.631 71.0611 303.042L265.953 400.487C277.85 388.374 294.415 380.86 312.736 380.86C330.533 380.86 346.673 387.95 358.487 399.459L601.233 271.344C600.568 267.598 600.221 263.742 600.221 259.805C600.221 223.592 629.578 194.236 665.791 194.236C702.004 194.236 731.361 223.592 731.361 259.805C731.361 296.019 702.004 325.375 665.791 325.375C648.084 325.375 632.016 318.357 620.218 306.95L377.337 435.137C377.974 438.806 378.306 442.579 378.306 446.43C378.306 482.643 348.949 512 312.736 512C276.523 512 247.166 482.643 247.166 446.43C247.166 443.081 247.417 439.79 247.902 436.575L50.187 337.719C19.4291 322.34 0 290.903 0 256.514V237.36C0 211.17 14.4951 187.131 37.6576 174.908L355.829 6.99775C373.092 -2.11324 393.689 -2.33956 411.148 6.38992ZM312.736 471.649C326.664 471.649 337.955 460.358 337.955 446.43C337.955 432.502 326.664 421.211 312.736 421.211C298.808 421.211 287.517 432.502 287.517 446.43C287.517 460.358 298.808 471.649 312.736 471.649ZM691.01 259.805C691.01 273.734 679.719 285.025 665.791 285.025C651.863 285.025 640.572 273.734 640.572 259.805C640.572 245.877 651.863 234.586 665.791 234.586C679.719 234.586 691.01 245.877 691.01 259.805Z"
                fill="#ffffff"></path>
            </svg>
          </a>
          <a className="social-item" href="https://discord.com/">
            <img src={icon_discord} />
          </a> */}
        </div>
      </div>
    </div>
  );
}
export default Footer;
