import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import customers_pc01 from '../../assets/img/customers_pc01.png';
import partner_pc01 from '../../assets/img/partner_pc01.jpeg';

function Customers(): ReactElement {
  const { t } = useTranslation('common');
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <div className="customers-page">
      <div className="wrapper">
        <div className="content">
          {/* <div className="text-header" dangerouslySetInnerHTML={{ __html: t('customers.section1_text1') }}></div>
          <div className="text-middle">{t('customers.section1_text2_pc')}</div> */}
          <div className="text-header">{t('customers.section1_text1')}</div>
          <div className="text-middle">{t('customers.section1_text2_pc')}</div>
          <div className="text-bottom">{t('customers.section1_text3')}</div>
        </div>
        <div>
          {' '}
          <img src={partner_pc01} alt="customers" className="img-customers01" />
        </div>
        {/* <div className="content hidden-pc">
          <div className="text-header" dangerouslySetInnerHTML={{ __html: t('customers.section1_text1') }}></div>
          <div className="text-middle">{t('customers.section1_text2_mb')}</div>
          <div className="text-bottom">{t('customers.section1_text3')}</div>
        </div> */}
      </div>
    </div>
  );
}
export default Customers;
