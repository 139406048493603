import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from '../modules/Home';
import Product from '../modules/Product';
import Layout from './Layout';
import Company from '../modules/Company';
import Partners from '../modules/Partners';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Fragment>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="product" element={<Product />} />
            <Route path="company" element={<Company />} />
            <Route path="partners" element={<Partners />} />
            <Route path="*" element={<Navigate to={'/'} />} />
          </Route>
        </Routes>
      </Fragment>
    </BrowserRouter>
  );
}
