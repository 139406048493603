import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import i18n from '../i18n';
function Layout(): ReactElement {
  const { i18n } = useTranslation('common');
  console.log(i18n.language, 'layout');
  return (
    <div className={i18n.language === 'kr' ? 'family-ko' : 'family-en'}>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}
export default Layout;
