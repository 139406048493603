import { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import IconNavbar from '../../assets/img/icon_navbar_main.png';
import IconNavbarOther from '../../assets/img/icon_navbar_other.png';
import popup_talken_main from '../../assets/img/popup_talken_main.png';
import popup_taal_main from '../../assets/img/popup_taal_main.png';
import popup_talken_otherpage from '../../assets/img/popup_talken_otherpage.png';
import popup_taal_otherpage from '../../assets/img/popup_taal_otherpage.png';
import icon_cancel from '../../assets/img/icon_cancel.png';
import logo_talkenmb from '../../assets/img/logo_talkenmb.png';
import logo_taalmb from '../../assets/img/logo_taalmb.png';
import logo_headerpc_main from '../../assets/img/logo_headerpc_main.png';
import logo_headerpc_otherpage from '../../assets/img/logo_headerpc_otherpage.png';
import logo_headermb from '../../assets/img/logo_headermb.png';
import logo_headermb_otherpage from '../../assets/img/logo_headermb_otherpage.png';
import nav_defi_icon from '../../assets/img/nav-defi.png';
import nav_nft_icon from '../../assets/img/nav-nft.png';
import nav_nft_wallet_icon from '../../assets/img/nav-nft-wallet.png';
import nav_about_us from '../../assets/img/nav-about-us.png';
import nav_contact_us from '../../assets/img/nav-contact-us.png';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Accordion, Nav } from 'react-bootstrap';
function Navbar(): ReactElement {
  let location = useLocation();
  const custom_navbar = location.pathname === '/' ? 'white' : '#113577';
  const active_navitem = location.hash;
  const [showNavbar, setShowNavbar] = useState(false);
  const { t } = useTranslation('common');
  let navigate = useNavigate();
  return (
    <>
      <div className={`nav-bar ${custom_navbar === 'white' ? '' : 'bg-white'}`}>
        <div className="wrap-menu" style={{ color: custom_navbar }}>
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <div className="header-logo hidden-mobile">
              {custom_navbar === 'white' ? (
                <img src={logo_headerpc_main} alt="Logo Colligence" />
              ) : (
                <img src={logo_headerpc_otherpage} alt="Logo Colligence" />
              )}
            </div>
            <div className="header-logo hidden-pc">
              {custom_navbar === 'white' ? (
                <img src={logo_headermb} alt="Logo Colligence" />
              ) : (
                <img src={logo_headermb_otherpage} alt="Logo Colligence" />
              )}
            </div>
          </Link>
          <ul className="nav hidden-mobile">
            <li>
              <NavLink
                to={'/company'}
                className="nav-link show-popup"
                style={({ isActive }) => ({
                  borderBottom: isActive ? `2px solid ${custom_navbar}` : 'none',
                  color: custom_navbar,
                  paddingLeft: '2px',
                  paddingRight: '2px',
                })}>
                {t('navbar.company')}
                <div className="popup-container" onClick={e => e.preventDefault()}>
                  <div className="popup-main">
                    <div className="wrapper-popup">
                      <div className="about-us" onClick={() => (window.location.href = '/company#about-us-des')}>
                        <div className="img-wrap">
                          <img src={nav_about_us} alt="" />
                        </div>
                        <div className="text-about-us">
                          {/* <div className="title">NFT Wallet</div> */}
                          <div className="sub">{t('navbar.popup_text4')}</div>
                        </div>
                      </div>
                      <div className="contact-us" onClick={() => (window.location.href = '/company#contact-us-des')}>
                        <div className="img-wrap">
                          <img src={nav_contact_us} alt="" />
                        </div>
                        <div className="text-contact-us">
                          {/* <div className="title">NFT</div> */}
                          <div className="sub">{t('navbar.popup_text5')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </li>

            <li>
              <NavLink
                to={'/product'}
                className="nav-link show-popup"
                style={({ isActive }) => ({
                  borderBottom: isActive ? `2px solid ${custom_navbar}` : 'none',
                  color: custom_navbar,
                  paddingLeft: '2px',
                  paddingRight: '2px',
                })}>
                {t('navbar.product')}
                {/* {custom_navbar === 'white' ? ( */}
                {true ? (
                  <div className="popup-container" onClick={e => e.preventDefault()}>
                    <div className="popup-main">
                      <div className="wrapper-popup">
                        <div className="nft-wallet">
                          <div className="img-wrap">
                            <img src={nav_nft_wallet_icon} alt="" />
                          </div>
                          <div
                            className="text-nft-wallet"
                            onClick={() => (window.location.href = '/product#nft-wallet-des')}>
                            {/* <div className="title">NFT Wallet</div> */}
                            <div className="sub">{t('navbar.popup_text1')}</div>
                          </div>
                        </div>
                        <div className="nft" onClick={() => (window.location.href = '/product#nft-des')}>
                          <div className="img-wrap">
                            <img src={nav_nft_icon} alt="" />
                          </div>
                          <div className="text-nft">
                            {/* <div className="title">NFT</div> */}
                            <div className="sub">{t('navbar.popup_text2')}</div>
                          </div>
                        </div>
                        <div className="defi" onClick={() => (window.location.href = '/product#defi-des')}>
                          <div className="img-wrap">
                            <img src={nav_defi_icon} alt="" />
                          </div>
                          <div className="text-defi">
                            {/* <div className="title">DeFi</div> */}
                            <div className="sub">{t('navbar.popup_text3')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="popup-container">
                    <div className="popup-otherpage" onClick={e => e.preventDefault()}>
                      <div className="wrapper-popup">
                        <div className="nft-wallet">
                          <div className="img-wrap">
                            <img src={nav_nft_wallet_icon} alt="" />
                          </div>
                          <div
                            className="text-nft-wallet"
                            onClick={() => (window.location.href = '/product#nft-wallet-des')}>
                            <div className="title">NFT Wallet</div>
                            <div className="sub">{t('navbar.popup_text1')}</div>
                          </div>
                        </div>
                        <div className="nft" onClick={() => (window.location.href = '/product#nft-des')}>
                          <div className="img-wrap">
                            <img src={nav_nft_icon} alt="" />
                          </div>
                          <div className="text-nft">
                            <div className="title">NFT</div>
                            <div className="sub">{t('navbar.popup_text2')}</div>
                          </div>
                        </div>
                        <div className="defi" onClick={() => (window.location.href = '/product#defi-des')}>
                          <div className="img-wrap">
                            <img src={nav_defi_icon} alt="" />
                          </div>
                          <div className="text-defi">
                            <div className="title">DeFi</div>
                            <div className="sub">{t('navbar.popup_text3')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={'/partners'}
                className="nav-link"
                style={({ isActive }) => ({
                  borderBottom: isActive ? `2px solid ${custom_navbar}` : 'none',
                  color: custom_navbar,
                  paddingLeft: '2px',
                  paddingRight: '2px',
                })}>
                {t('navbar.partners')}
              </NavLink>
            </li>
          </ul>
          <div className="text-end d-flex justify-content-center align-items-center">
            {/* {i18n.language === 'en' ? (
              <span
                className="hover"
                style={{ paddingRight: '20px', color: custom_navbar }}
                onClick={() => i18n.changeLanguage('kr')}>
                EN
              </span>
            ) : (
              <span
                className="hover"
                style={{ paddingRight: '20px', color: custom_navbar }}
                onClick={() => i18n.changeLanguage('en')}>
                KR
              </span>
            )}
            <span className="" style={{ color: '#8B9BB8' }}>
              |
            </span> */}
            <div style={{ width: '100px' }}></div>
            {location.pathname === '/' ? (
              <img
                src={IconNavbar}
                style={{ paddingLeft: '20px' }}
                // className='hidden-pc'
                alt="Icon navbar"
                onClick={() => setShowNavbar(!showNavbar)}
                className="hover"
              />
            ) : (
              <img
                src={IconNavbarOther}
                style={{ paddingLeft: '20px' }}
                // className='hidden-pc'
                alt="Icon navbar other"
                onClick={() => setShowNavbar(!showNavbar)}
                className="hover"
              />
            )}
          </div>
        </div>
      </div>
      {/* navbar mobile */}
      <div className={`navbar-mb  ${showNavbar ? 'show' : ''}`}>
        <div className="header">
          {' '}
          <Link to={'/'} style={{ textDecoration: 'none', color: '#ffffff' }}>
            <div className="header-logo" onClick={() => setShowNavbar(!showNavbar)}>
              <img src={logo_headermb} alt="Logo Colligence" />
            </div>
          </Link>
          <div className="text-end d-flex justify-content-center align-items-center">
            {/* {i18n.language === 'en' ? (
              <span
                className=""
                style={{ paddingRight: '20px', fontSize: '17px' }}
                onClick={() => i18n.changeLanguage('kr')}>
                EN
              </span>
            ) : (
              <span
                className=""
                style={{ paddingRight: '20px', fontSize: '17px' }}
                onClick={() => i18n.changeLanguage('en')}>
                KR
              </span>
            )}
            <span className="" style={{ color: '#8B9BB8' }}>
              |
            </span> */}
            <img
              src={icon_cancel}
              style={{ paddingLeft: '20px' }}
              alt="Icon navbar"
              onClick={() => setShowNavbar(!showNavbar)}
              className="hover"
            />
          </div>
        </div>
        <div className="wrap-menu">
          <Accordion className="custom-nav" alwaysOpen>
            <Accordion.Item eventKey="0" style={{ backgroundColor: '#113577' }}>
              <Accordion.Header>
                {' '}
                <NavLink
                  to={'/company'}
                  className="nav-link"
                  style={({ isActive }) => ({
                    opacity: isActive ? '' : 0.8,
                  })}
                  onClick={() => setShowNavbar(!showNavbar)}>
                  {t('navbar.company')}
                </NavLink>
              </Accordion.Header>
              <Accordion.Body>
                <a
                  href={`${location.pathname === '/company' ? '#about-us-des' : '/company#about-us-des'}`}
                  onClick={() => {
                    setShowNavbar(!showNavbar);
                  }}
                  className={`${active_navitem === '#about-us-des' ? 'active-nav' : ''}`}>
                  {t('navbar.popup_text4')}
                </a>
                <br />
                <a
                  href={`${location.pathname === '/company' ? '#contact-us-des' : '/company#contact-us-des'}`}
                  onClick={() => {
                    setShowNavbar(!showNavbar);
                  }}
                  className={`${active_navitem === '#contact-us-des' ? 'active-nav' : ''}`}>
                  {t('navbar.popup_text5')}
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <NavLink
                  to={'/product'}
                  className="nav-link"
                  style={({ isActive }) => ({
                    opacity: isActive ? '' : 0.8,
                  })}
                  onClick={() => setShowNavbar(!showNavbar)}>
                  {t('navbar.product')}
                </NavLink>
              </Accordion.Header>
              <Accordion.Body>
                <a
                  href={`${location.pathname === '/product' ? '#nft-wallet-des' : '/product#nft-wallet-des'}`}
                  onClick={() => {
                    setShowNavbar(!showNavbar);
                  }}
                  className={`${active_navitem === '#nft-wallet-des' ? 'active-nav' : ''}`}>
                  {t('navbar.popup_text1')}
                </a>
                <br />
                <a
                  href={`${location.pathname === '/product' ? '#nft-des' : '/product#nft-des'}`}
                  onClick={() => {
                    setShowNavbar(!showNavbar);
                  }}
                  className={`${active_navitem === '#nft-des' ? 'active-nav' : ''}`}>
                  {t('navbar.popup_text2')}
                </a>
                <br />
                <a
                  href={`${location.pathname === '/product' ? '#defi-des' : '/product#defi-des'}`}
                  onClick={() => {
                    setShowNavbar(!showNavbar);
                  }}
                  className={`${active_navitem === '#defi-des' ? 'active-nav' : ''}`}>
                  {t('navbar.popup_text3')}
                </a>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="nav-partners">
              <Accordion.Header>
                <NavLink
                  to={'/partners'}
                  className="nav-link"
                  style={({ isActive }) => ({
                    opacity: isActive ? '' : 0.8,
                  })}
                  onClick={() => setShowNavbar(!showNavbar)}>
                  {t('navbar.partners')}
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}
export default Navbar;
