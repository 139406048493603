import { ReactElement, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import pricing_pc01 from '../../assets/img/pricing_pc01.svg';
import contact_us from '../../assets/img/contact_us.svg';

function Pricing(): ReactElement {
  const { t } = useTranslation('common');
  let navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pricing-page">
      <div className="section-01" id="about-us-des">
        <div className="wrapper">
          <div className="content hidden-mobile">
            <div className="text-header" dangerouslySetInnerHTML={{ __html: t('pricing.section1_text1_pc') }}></div>
            <div className="text-bottom">{t('pricing.section1_text2_pc')}</div>
            <div className="text-bottom">{t('pricing.section1_text3_pc')}</div>
            {/* <button className="btn-pricing">{t('pricing.section1_text4')}</button> */}
          </div>
          <div>
            {' '}
            <img src={pricing_pc01} alt="pricing" className="img-pricing01" />
          </div>
          <div className="content hidden-pc">
            <div className="text-header" dangerouslySetInnerHTML={{ __html: t('pricing.section1_text1_mb') }}></div>
            <div className="text-bottom">{t('pricing.section1_text2_mb')}</div>
            <div className="text-bottom">{t('pricing.section1_text3_mb')}</div>
            {/* <button className="btn-pricing">{t('pricing.section1_text4')}</button> */}
          </div>
        </div>
      </div>
      <div className="section-02" id="contact-us-des">
        <div className="wrapper">
          <div className="contact-us-wrapper">
            {' '}
            <img src={contact_us} alt="pricing" className="img-pricing01" />
            <div className="contact-img-text">{t('pricing.section1_text2_pc')}</div>
          </div>
          <div className="content hidden-mobile">
            <div className="text-header" dangerouslySetInnerHTML={{ __html: t('pricing.section2_text1_pc') }}></div>
            <div className="text-bottom">{t('pricing.section2_text2_pc')}</div>
            <button className="btn-pricing" onClick={() => window.open('https://forms.gle/MDa2EJaXh3Yj9pF3A')}>
              {t('pricing.section2_text3')}
            </button>
          </div>
          <div className="content hidden-pc">
            <div className="text-header" dangerouslySetInnerHTML={{ __html: t('pricing.section2_text1_mb') }}></div>
            <div className="text-bottom">{t('pricing.section2_text2_mb')}</div>
            <button className="btn-pricing" onClick={() => window.open('https://forms.gle/MDa2EJaXh3Yj9pF3A')}>
              {t('pricing.section2_text3')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Pricing;
