import { ReactElement, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import main_pc02 from '../../assets/img/main_pc02.png';
import main_mb02 from '../../assets/img/main_mb02.png';
import main_pc03 from '../../assets/img/main_pc03.png';
import main_pc04 from '../../assets/img/main_pc04.png';
import main_pc05 from '../../assets/img/main_pc05.png';
import main_pc06 from '../../assets/img/main_pc06.png';
import { useNavigate } from 'react-router-dom';
const scrollHandler = () => {
  const nav = document.querySelector('.nav-bar');
  nav?.classList.toggle('bg-blue', window.scrollY > 500);
};
function Home(): ReactElement {
  const { t, i18n } = useTranslation('common');
  let navigate = useNavigate();
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
  return (
    <div className="home-page">
      <div className="home-header">
        <div className="wrapper-content">
          {/* <div className="text-header">
            Web3 어플리케이션을 {'\n'}
            가장 쉽게
          </div> */}
          <div className="text-header">{t('main.section1_header1')}</div>
          <div className="text-bottom hidden-mobile">{t('main.section1_header2_pc')}</div>
          <div className="text-bottom-mb hidden-pc">{t('main.section1_header2_mb')}</div>
          <img src={main_pc02} alt="" className="main-pc-img hidden-mobile" />
          <img src={main_mb02} alt="" className="main-mb-img hidden-pc" />
        </div>
      </div>

      <div className="home-content">
        <div className="content-header">
          <div className="wrapper-right hidden-mobile">
            <div className="text-header">{t('main.section2_text1')}</div>
            <div className="text-bottom">{t('main.section2_text2_pc')}</div>
            <button className="btn-explore" onClick={() => (window.location.href = '/product#nft-wallet-des')}>
              {t('main.section2_text3')} <img src={main_pc05} />
            </button>
          </div>
          <div className="main-pc03">
            <img src={main_pc03} alt="" />
          </div>

          <div className="wrapper-right hidden-pc">
            <div className="text-header">{t('main.section2_text1')}</div>
            <div className="text-bottom">{t('main.section2_text2_mb')}</div>
            <button className="btn-explore" onClick={() => (window.location.href = '/product#nft-wallet-des')}>
              {t('main.section2_text3')} <img src={main_pc05} />
            </button>
          </div>
        </div>
        <div className="content-second">
          <div className="main-pc06">
            <img src={main_pc06} alt="" />
          </div>
          <div className="wrapper-right">
            <div className="text-header">{t('main.section2_text4')}</div>
            <div className="text-bottom hidden-mobile">{t('main.section2_text5_pc')}</div>
            <div className="text-bottom hidden-pc">{t('main.section2_text5_mb')}</div>
            <button className="btn-explore" onClick={() => (window.location.href = '/product#nft-des')}>
              {t('main.section2_text3')}
              <img src={main_pc05} />
            </button>
          </div>
        </div>
        <div className="content-bottom">
          <div className="wrapper-right hidden-mobile">
            <div className="text-header">{t('main.section2_text6')}</div>
            <div className="text-bottom">{t('main.section2_text7_pc')}</div>
            <button className="btn-explore" onClick={() => (window.location.href = '/product#defi-des')}>
              {t('main.section2_text3')} <img src={main_pc05} />
            </button>
          </div>
          <div className="main-pc04">
            <img src={main_pc04} alt="" />
          </div>
          <div className="wrapper-right hidden-pc">
            <div className="text-header">{t('main.section2_text6')}</div>
            <div className="text-bottom">{t('main.section2_text7_mb')}</div>
            <button className="btn-explore" onClick={() => (window.location.href = '/product#defi-des')}>
              {t('main.section2_text3')} <img src={main_pc05} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
